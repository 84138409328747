import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.5) => {
  const { body } = domStorage
  const { current } = viewStorage

  let pageHeader = null
  if(current.querySelector('.page__header--container')) pageHeader = current.querySelector('.page__header--container')
  if(current.querySelector('.home__header--container')) pageHeader = current.querySelector('.home__header--container')

  const pageTitle = pageHeader ? pageHeader.querySelector('h1') : null
  const pageSubtitle = pageHeader ? pageHeader.querySelector('.subtitle > span') : null
  const pageHeaderTag = pageHeader ? pageHeader.querySelector('.tag') : null
  const pageHeaderIntro = pageHeader ? pageHeader.querySelectorAll('.col p') : null
  const pageHeaderBtn = pageHeader ? pageHeader.querySelector('.Btn') : null
  const pageHeaderInstructions = pageHeader ? pageHeader.querySelector('.home__header--instructions') : null
  const pageAnchors = pageHeader ? pageHeader.querySelector('.Anchors__nav') : null
  const pageSections = [...current.children]
  const allSectionsExceptTheFirst = pageSections.slice(1)
  const pageTitleLines = new SplitText(pageTitle, { type: 'lines' })
  const pageTitleLinesC = new SplitText(pageTitle, { type: 'lines', linesClass: '--oh' })

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power4.out', duration: 0.8 }
    })
    
    pageSubtitle && tl.fromTo(pageSubtitle, { yPercent: 100 }, { yPercent: 0 }, 0)
    pageHeaderTag && tl.from(pageHeaderTag, { width: '28px', x: -20 }, 0)
    pageTitleLines.lines.length && tl.fromTo(pageTitleLines.lines, { yPercent: 100 }, { yPercent: 0, stagger: 0.1 }, pageSubtitle ? '-=0.7' : 0)
    pageHeaderBtn && tl.fromTo(pageHeaderBtn, { opacity: 0 }, { opacity: 1 }, '-=0.6')
    pageHeaderIntro && tl.fromTo(pageHeaderIntro, { opacity: 0, y: 40 }, { opacity: 1, y: 0 }, '-=0.8')
    pageHeaderInstructions && tl.fromTo(pageHeaderInstructions, { yPercent: 100 }, { yPercent: 0 }, '-=0.8')
    pageAnchors && tl.fromTo(pageAnchors, { opacity: 0 }, { opacity: 1 }, '-=0.4')
    allSectionsExceptTheFirst.length && tl.fromTo(allSectionsExceptTheFirst, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.4')

  return tl
}
