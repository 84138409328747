import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Form from '../modules/form'
import Accordeons from '../modules/accordeons'
import StepsMenu from '../modules/steps-menu';

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {  
      sectionSteps: viewStorage.current.querySelector('.section--steps')
    }

    this.init()
  }

  init() {

    const { sectionSteps = null } = this.DOM

    this.accordeonsModule = new Accordeons(viewStorage.current)

    // console.log(viewStorage.current.querySelector('.swiperSteps'))
    this.stepsMenu = viewStorage.current.querySelectorAll('.steps--item .steps--item__nav');
    this.inputsConditional = viewStorage.current.querySelectorAll('input[type="radio"][name="hearAbout-segment_field"]');
    this.inputsConditional2 = viewStorage.current.querySelectorAll('input[type="radio"][name="dedicated-sustainability-team_field"]');
    this.inputsConditional3 = viewStorage.current.querySelectorAll('input[type="radio"][name="specific-topics_field"]');
    this.inputsConditional4 = viewStorage.current.querySelectorAll('input[type="radio"][name="other-org_field"]');

    if (sectionSteps) this.stepsMenuModule = new StepsMenu(sectionSteps)

    this.inputsConditional.forEach(
      input => {
        input.addEventListener('click', (e) => {
          const id = e.currentTarget.getAttribute('id')
          
          const idLinkedInput = ''
          if (id == 'hearAbout-segment_field_5') {
            if ((e.currentTarget.checked == true)) {
              const idLinkedInput = '.linkedInput-for-' + id
              viewStorage.current.querySelectorAll(idLinkedInput).forEach(
                conditionalInput => {
                  conditionalInput.classList.add('--active')
                }
              )
            }
          }
          else {
            e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll('.conditional').forEach(
              conditionalInput => {
                conditionalInput.classList.remove('--active')
              }
            )
          }
        })
      }
    )

    this.inputsConditional2.forEach(
      input => {
        input.addEventListener('click', (e) => {
          const id = e.currentTarget.getAttribute('id')
          const idLinkedInput = ''
          if (id == 'dedicated-sustainability-team_field_1') {
            if ((e.currentTarget.checked == true)) {
              const idLinkedInput = '.linkedCheckbox-for-' + id
              viewStorage.current.querySelectorAll(idLinkedInput).forEach(
                conditionalInput => conditionalInput.classList.add('--active')
              )
            }
          }
          else {
            e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll('.conditional').forEach(
              conditionalInput => conditionalInput.classList.remove('--active')
            )
          }
        })
      }
    )

    this.inputsConditional3.forEach(
      input => {
        input.addEventListener('click', (e) => {
          const id = e.currentTarget.getAttribute('id')
          const idLinkedInput = ''
          if (id == 'specific-topics_field_1') {
            if ((e.currentTarget.checked == true)) {
              const idLinkedInput = '.linkedInput-for-' + id
              viewStorage.current.querySelectorAll(idLinkedInput).forEach(
                conditionalInput => conditionalInput.classList.add('--active')
              )
            }
          }
          else {
            e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll('.conditional').forEach(
              conditionalInput => conditionalInput.classList.remove('--active')
            )
          }
        })
      }
    )

    this.inputsConditional4.forEach(
      input => {
        input.addEventListener('click', (e) => {
          const id = e.currentTarget.getAttribute('id')
          const idLinkedInput = ''
          if (id == 'other-org_field_1') {
            if ((e.currentTarget.checked == true)) {
              const idLinkedInput = '.linkedInput-for-' + id
              viewStorage.current.querySelectorAll(idLinkedInput).forEach(
                conditionalInput => conditionalInput.classList.add('--active')
              )
            }
          }
          else {
            e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll('.conditional').forEach(
              conditionalInput => conditionalInput.classList.remove('--active')
            )
          }
        })
      }
    )

    this.myForm = new Form(viewStorage.current.querySelector('.ContactForm'))
  }

  onLeaveCompleted() {
    this.accordeonsModule && this.accordeonsModule.destroy()
    this.stepsMenuModule && this.stepsMenuModule.destroy()

    if (this.myForm) this.myForm.destroy();
    this.stepsMenu.forEach(
      navItem => {
        navItem.removeEventListener('click', this.inputsConditional)
        // console.log("remove")
      }
    )
    this.inputsConditional.forEach(
      input => {
        input.removeEventListener('click', this.inputsConditional)
        // console.log("remove")
      }
    )
    this.inputsConditional2.forEach(
      input => {
        input.removeEventListener('click', this.inputsConditional2)
      }
    )
    this.inputsConditional3.forEach(
      input => {
        input.removeEventListener('click', this.inputsConditional3)
      }
    )
    this.inputsConditional4.forEach(
      input => {
        input.removeEventListener('click', this.inputsConditional4)
      }
    )
  }
}
